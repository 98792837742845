import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilUser,
  cilLockLocked,
  cilAccountLogout,
  cilQrCode,
  cilNewspaper,
  cilAsterisk,
  cilEnvelopeClosed,
  cilCheck,
  cilBan,
  cilCalendar,
  cibGmail,
} from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilUser,
    cilLockLocked,
    cilAccountLogout,
    cilQrCode,
    cilNewspaper,
    cilAsterisk,
    cilEnvelopeClosed,
    cilCheck,
    cilBan,
    cilCalendar,
    cibGmail,
  }
);
