import firebase from "firebase/app";
import "firebase/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyAvOG-iuRgKQyqO0LLdftUd2y3rotyxtP8",
  authDomain: "login-mebrom.firebaseapp.com",
  projectId: "login-mebrom",
  storageBucket: "login-mebrom.appspot.com",
  messagingSenderId: "483607451196",
  appId: "1:483607451196:web:b8f5ab8298e9331c76d539",
});

export const auth = app.auth();
export default app;
