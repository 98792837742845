const language = {
  EN: {
    acknowledge: "Acknowledge",
    acknowledgeSuccess:
      "We received your acknowledgment of the Mebrom Methyl Bromide Stewardship Policy for %YEAR%. Click here to be directed to your dashboard area.",
    category: "Category",
    document: "Document",
    finishDoc:
      "Please, review the entire Stewardship Policy before marking your acknowledgment",
    pdfList: "Your Documents",
    refresh: "Refresh",
    view: "View",
    sds: "Safety Data Sheet",
    stewardship: "Stewardship Policy",
    label: "Label",
    logout: "Log Out",
  },
  VN: {
    acknowledge: "XÁC NHẬN",
    acknowledgeSuccess:
      "Chúng tôi đã nhận được xác nhận của bạn về Chính sách quản lý Mebrom Methyl Bromide cho năm %YEAR%. Nhấp vào đây để được dẫn đến khu vực Bảng Điều Khiển của bạn.",
    category: "Thể loại",
    document: "Hồ Sơ",
    finishDoc:
      "Vui lòng xem lại toàn bộ Chính sách Quản lý trước khi thực hiện việc Xác nhận",
    pdfList: "Danh sách tệp tin (PDF)",
    refresh: "Làm mới",
    view: "Lượt xem",
    sds: "Bảng Dữ liệu An Toàn",
    stewardship: "Chính sách Quản Lý",
    label: "Nhãn",
    logout: "Đăng Xuất",
  },
  ES: {
    acknowledge: "Acuse de recibo",
    acknowledgeSuccess:
      "Hemos recibido su acuse de recibo de la Política de administración de Bromuro de Metilo  de Mebrom para %YEAR%. Haga clic aquí para acceder al área de su panel de control.",
    category: "Categoría",
    document: "Documento",
    finishDoc:
      "Por favor, lea detenidamente toda la Política de administración antes de marcar acuse de recibo.",
    pdfList: " Lista PDF",
    refresh: "Actualizar",
    view: "Ver",
    sds: "Hoja de seguridad",
    stewardship: "Politicas de Administración",
    label: "Labels",
    logout: "Cerrar sesión",
  },
  TH: {
    acknowledge: "รับทราบ",
    acknowledgeSuccess:
      "เราได้รับการตอบรับจากคุณเกี่ยวกับนโยบายการดูแลมีบรอมเมทริลโบรไมด์ประจำปี%YEAR%แล้ว. กรุณา คลิกที่นี่ ระบบจะนำคุณกลับไปยังแดชบอร์ด",
    category: "หมวดหมู่",
    document: "เอกสาร",
    finishDoc: "กรุณาอ่านนโยบายการดูแลทั้งหมดก่อนกดรับทราบ",
    pdfList: "รายชื่อ PDF",
    refresh: "รีเฟรช",
    view: "ดู",
    sds: "เอกสารข้อมูลความปลอดภัย",
    stewardship: "นโยบายการดูแล",
    label: "ป้ายกำกับ",
    logout: "ล๊อกเอาท์",
  },
};

export default language;
