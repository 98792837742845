import React, { useContext, useState } from "react";
import { useAuth } from "./AuthContext";
import axios from "axios";
import app from "src/config/firebase";
import language from "../config/language";

const DatabaseContext = React.createContext();

export function useDatabase() {
  return useContext(DatabaseContext);
}

export function DatabaseProvider({ children }) {
  const { getAuthToken, currentUser } = useAuth();
  const [userList, setUserList] = useState();
  const [pdfList, setPDFList] = useState();

  async function getUsers() {
    let token = await getAuthToken();

    setUserList();
    axios
      .get("/api/users/all", {
        headers: { "Content-Type": "application/json", Authorization: token },
      })
      .then(async (res) => {
        console.log(res.data);
        let data = res.data;

        let map = await getNoAckNoAssigned();
        console.log(map);
        for (let i of data) {
          if (i.role === "client0") {
            i.acknowledge = !(i.uid in map);
            // let { ack, rows1 } = await checkAck(i.uid);
            // console.log(ack, rows1);

            // if (rows1.length > 0 && ack) {
            //   let smth = [...rows1];

            //   smth.sort((a, b) => {
            //     const dateA = a["dateAck"].split("/").reverse().join();
            //     const dateB = b["dateAck"].split("/").reverse().join();
            //     return dateB.localeCompare(dateA);
            //   });

            //   i.acknowledgedDate = smth[0].dateAck;
            // } else {
            //   i.acknowledgedDate = "";
            // }
            i.role = "Client - Primary";
          } else if (i.role === "client1") {
            i.role = "Client – App Only";
          } else if (i.role === "client") {
            i.role = "Client - SP + App";
          }
        }
        setUserList(data);
      });
  }

  //
  async function createUser(
    email,
    password,
    name,
    role,
    toggle,
    history,
    company
  ) {
    let token = await getAuthToken();

    axios
      .post(
        "/api/users/",
        {
          email,
          password,
          name,
          role,
          company,
        },
        {
          headers: { "Content-Type": "application/json", Authorization: token },
        }
      )
      .then((res) => {
        if (role === "client" || role === "client1" || role === "client0") {
          history.push("/admin/users/" + res.data.uid);
        } else {
          getUsers();
          toggle();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err?.response?.data?.msg);
      });
  }

  async function deleteUser(uid) {
    if (window.confirm("Do you want to delete this user")) {
      let token = await getAuthToken();

      axios
        .post(
          "/api/users/delete",
          {
            uid: uid,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((res) => {
          alert("Successfully Deleted the user");
          getUsers();
        })
        .catch((err) => {
          alert(err?.response?.data?.msg);
        });
    }
  }

  async function getAllPDF() {
    let token = await getAuthToken();

    axios
      .get("/api/document/", {
        headers: { "Content-Type": "application/json", Authorization: token },
      })
      .then((res) => {
        setPDFList(res.data);
      });
  }

  async function uploadPDF(title, type, file, titleInput, toggle) {
    if (!title) {
      titleInput.classList.add("is-invalid");
      return;
    } else {
      let token = await getAuthToken();

      const data = new FormData();
      data.append("title", title);
      data.append("type", type);
      data.append("Authorization", token);
      data.append("pdf", file);

      axios
        .post("/api/document/", data)
        .then((res) => {
          toggle();
          alert("Upload Successfully");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  async function reupload(id, file, setFile, pdfPath) {
    if (!file) {
      alert("There is no file to upload!");
      return;
    }
    if (window.confirm("Are you sure you want to upload this file?")) {
      let token = await getAuthToken();

      const data = new FormData();
      data.append("id", id);
      data.append("Authorization", token);
      data.append("pdfPath", pdfPath);
      data.append("pdf", file);

      axios
        .post("/api/document/reupload", data)
        .then(() => {
          getAllPDF();
          alert("Upload Successfully");
          setFile();
          window.location.reload();
        })
        .catch((err) => alert(err?.response?.data?.msg));
    }
  }

  async function rename(item, name, setTitle) {
    let id = item.id;
    if (item.title === name) {
      alert("No change was made!");
      return;
    }
    if (window.confirm("Are you sure you want to rename this file?")) {
      let token = await getAuthToken();

      axios
        .post(
          "/api/document/name",
          {
            id,
            name,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then(() => {
          getAllPDF();
          alert("Rename Successfully");
          window.location.reload();
        })
        .catch((err) => alert(err?.response?.data?.msg));
    }
  }

  async function deletePDF(id, pdfPath) {
    if (window.confirm("Are you sure you want to delete this file?")) {
      let token = await getAuthToken();

      axios
        .post(
          "/api/document/delete",
          {
            id,
            pdfPath,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then(() => {
          getAllPDF();
          alert("Delete Successfully");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          alert(err?.response?.data?.msg);
        });
    }
  }

  async function getPDF(pdfPath, setFilePDF) {
    let token = await getAuthToken();

    axios
      .post(
        "/api/document/view",
        {
          pdfPath,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        const bytes = atob(res.data.data);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
          out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: "application/pdf" });
      })
      .then((blob) => {
        setFilePDF(URL.createObjectURL(blob));
      })
      .catch((err) => alert(err?.response?.data?.msg));
  }

  async function getTypeOfPDF(type, setList) {
    let token = await getAuthToken();

    axios
      .get(`/api/document/category?type=${type}`, {
        headers: { "Content-Type": "application/json", Authorization: token },
      })
      .then((res) => {
        let ret = [];
        for (let i of res.data) {
          ret.push({ value: i.id, label: i.title });
        }
        ret.sort((a, b) => a.label.localeCompare(b.label));
        setList(ret);
      });
  }

  async function getUser(uid, setUserDetails) {
    let token = await getAuthToken();
    console.log("calling getUsers");
    axios
      .get(`/api//users/individual?uid=${uid}`, {
        headers: { "Content-Type": "application/json", Authorization: token },
      })
      .then((res) => {
        setUserDetails(res.data);
      })
      .catch((err) => {
        alert(err?.response?.data?.msg);
      });
  }

  //
  async function updateNameAndEmail(
    uid,
    name,
    email,
    ccmail,
    language,
    company
  ) {
    if (window.confirm("Are you sure you want to update name and email?")) {
      let token = await getAuthToken();

      // let appSettings = "";
      // if (appSetting.length > 0) {
      //   appSettings = `${appSetting[0].value}`;
      //   for (let i of appSetting.splice(1)) {
      //     appSettings += `,${i.value}`;
      //   }
      // }

      await axios
        .post(
          "/api/users/general",
          { uid, name, email, ccmail, language, company },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((res) => {
          alert("Update Name And E-mail Succesfully");
        })
        .catch((err) => {
          alert(err?.response?.data?.msg);
        });
    }
  }

  async function updatePassword(uid, password, setPassword) {
    if (window.confirm("Are you sure you want to update the password?")) {
      let token = await getAuthToken();

      await axios
        .post(
          "/api/users/password",
          { uid, password },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((res) => {
          alert("Update Password Succesfully");
        })
        .catch((err) => {
          alert(err?.response?.data?.msg);
        });
    }
  }

  async function updatePDFOptions(uid, packaging, safetydatasheet, label) {
    let arr = [];
    for (let i of packaging) {
      arr.push({ uid, id: i.value });
    }
    for (let i of safetydatasheet) {
      arr.push({ uid, id: i.value });
    }
    for (let i of label) {
      arr.push({ uid, id: i.value });
    }

    let options;
    if (arr.length > 0) {
      options = `('${arr[0].uid}','${arr[0].id}')`;

      for (let i of arr.splice(1)) {
        options += `,('${i.uid}','${i.id}')`;
      }
    }

    let token = await getAuthToken();

    axios
      .post(
        "/api/users/document",
        { options, uid },
        {
          headers: { "Content-Type": "application/json", Authorization: token },
        }
      )
      .then((res) => {
        alert("Update PDF Options Successfully");
      })
      .catch((err) => {
        alert(err?.response?.data?.msg);
      });
  }

  async function getUserPDFOptions(
    uid,
    setPackaging,
    setSafetyDataSheet,
    setLabel
  ) {
    let token = await getAuthToken();

    axios
      .get("/api/users/document?uid=" + uid, {
        headers: { "Content-Type": "application/json", Authorization: token },
      })
      .then((res) => {
        let packaging = [];
        let sds = [];
        let label = [];
        console.log(res.data);
        for (let i of res.data) {
          if (i.type === "stewardship")
            packaging.push({ label: i.title, value: i.id });
          if (i.type === "safetydatasheet")
            sds.push({ label: i.title, value: i.id });
          if (i.type === "label") label.push({ label: i.title, value: i.id });
        }
        packaging.sort((a, b) => a.label.localeCompare(b.label));
        sds.sort((a, b) => a.label.localeCompare(b.label));
        label.sort((a, b) => a.label.localeCompare(b.label));
        setPackaging(packaging);
        setSafetyDataSheet(sds);
        setLabel(label);
      })
      .catch((err) => {
        console.log(err);
        alert(err?.response?.data?.msg);
      });
  }

  async function updateStickerOptions(
    uid,
    stickerOptions,
    batchText,
    clientText,
    expiryText,
    cylinderText,
    fontSize,
    qrSize,
    stickerHeight,
    stickerWidth,
    appSetting,
    productionText,
    qiGenInput,
    cylTypeText,
    prodTypeText,
    refText,
    stickerType,
    free1Text,
    free2Text,
    free3Text,
    free4Text,
    topMargin,
    suffix
  ) {
    let appSettings = "";
    if (appSetting.length > 0) {
      appSettings = `${appSetting[0].value}`;
      for (let i of appSetting.splice(1)) {
        appSettings += `,${i.value}`;
      }
    }

    let str;
    let arr = [...stickerOptions];

    if (arr.length > 0) {
      str = `${arr[0]}`;

      for (let i of arr.splice(1)) {
        str += `,${i}`;
      }
    }

    let token = await getAuthToken();

    let generateSticker = qiGenInput == "enable" ? 1 : 0;
    axios
      .post(
        "/api/users/sticker",
        {
          uid,
          str,
          batchText,
          clientText,
          expiryText,
          cylinderText,
          fontSize,
          qrSize,
          stickerHeight,
          stickerWidth,
          appSettings,
          productionText,
          generateSticker,
          cylTypeText,
          prodTypeText,
          refText,
          stickerType,
          free1Text,
          free2Text,
          free3Text,
          free4Text,
          topMargin,
          suffix
        },
        {
          headers: { "Content-Type": "application/json", Authorization: token },
        }
      )
      .then((res) => {
        alert("Update Sticker Options Successfully");
        window.location.reload(false);
      })
      .catch((err) => {
        alert(err?.response?.data?.msg);
      });
  }

  async function getQIGenOptions(uid, setQiGenInput) {
    let token = await getAuthToken();

    axios
      .get("/api/users/custom?fields=generateSticker&uid=" + uid, {
        headers: { "Content-Type": "application/json", Authorization: token },
      })
      .then((res) => {
        let string = res.data.generateSticker == 1 ? "enable" : "disable";
        setQiGenInput(string);
      })
      .catch((err) => {
        alert(err?.response?.data?.msg);
      });
  }

  async function getUserStickerOptions(
    uid,
    setStickerOptions,
    setBatchText,
    setClientText,
    setExpiryText,
    setCylinderText,
    setFontSize,
    setQRSize,
    setStickerWidth,
    setStickerHeight,
    setProductionText,
    setProdTypeText,
    setCylTypeText,
    setRefText,
    setStickerType,
    setFree1Text,
    setFree2Text,
    setFree3Text,
    setFree4Text,
    setTopMargin,
    setSuffixInput
  ) {
    let token = await getAuthToken();

    axios
      .get("/api/users/individual?uid=" + uid, {
        headers: { "Content-Type": "application/json", Authorization: token },
      })
      .then((res) => {
        if (res.data) {
          let stickerOps = [];
          if (res.data.displayColumns) {
            for (let i of res.data.displayColumns.split(",")) {
              stickerOps.push(i);
            }
          }
          setStickerOptions(stickerOps);
          setBatchText(res.data.textBatchNumber);
          setClientText(res.data.textClientName);
          setExpiryText(res.data.textExpiryDate);
          setCylinderText(res.data.textCylinderNumber);
          setProductionText(res.data.textProductionDate);
          setFontSize(res.data.fontSize);
          setQRSize(res.data.qrSize);
          setStickerWidth(res.data.stickerWidth);
          setStickerHeight(res.data.stickerHeight);
          setProdTypeText(res.data.textProdType);
          setCylTypeText(res.data.textCylType);
          setRefText(res.data.textRef);
          setStickerType(res.data.stickerType);
          setFree1Text(res.data.textFree1);
          setFree2Text(res.data.textFree2);
          setFree3Text(res.data.textFree3);
          setFree4Text(res.data.textFree4);
          setTopMargin(res.data.topMargin);
          setSuffixInput(res.data.suffix == 1 ? 'enable' : 'disable');
        } else {
          setStickerOptions([]);
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err?.response?.data?.msg);
      });
  }

  async function getUserDashboard(uid, setUserAck, setUserPDFList) {
    let token = await getAuthToken();

    axios
      .get("/api/users/document?uid=" + uid, {
        headers: { "Content-Type": "application/json", Authorization: token },
      })
      .then((res) => {
        setUserPDFList(res.data);
        // setUserAck(true);
        // if (res.data.ack) {
        //   setUserAck(true);
        // } else {
        //   setUserAck(false);
        // }
      });
  }

  async function getStewardship(setPath, id, uid, setAcked, setYear) {
    let token = await getAuthToken();

    axios
      .get(`/api/document/stewardship?id=${id}&uid=${uid}`, {
        headers: { "Content-Type": "application/json", Authorization: token },
      })
      .then((res) => {
        if (res.data.acked) {
          setAcked(true);
          setYear(res.data.rows[0].lastUpdate.split("/")[2]);
        } else {
          setAcked(false);
        }
        setPath("./" + res.data.rows[0].pdfPath);
      });
  }

  async function acknowledge(uid, history, id) {
    let token = await getAuthToken();

    await axios
      .post(
        "/api/document/acknowledge",
        { uid, id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        const date = new Date();
        alert(
          language[currentUser.language].acknowledgeSuccess.replace(
            /%YEAR%/g,
            date.getFullYear()
          )
        );
        history.push("/");
      })
      .catch((err) => {
        alert(err?.response?.data?.msg);
      });
  }

  function calculateExpiryDate(productionDate) {
    if (productionDate) {
      return ExcelDateToJSDate(productionDate);
    } else {
      return "";
    }
  }

  function ExcelDateToJSDate(serial) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    return date_info.getMonth() + "/" + date_info.getFullYear();
    // new Date(
    //   date_info.getFullYear(),
    //   date_info.getMonth(),
    //   date_info.getDate(),
    //   hours,
    //   minutes,
    //   seconds
    // );
  }

  async function getTable(setTable) {
    let token = await getAuthToken();
    // let lists_of_enable;

    // await axios
    //   .post(
    //     "/api/getEnableQIGen",
    //     {},
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: token,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     lists_of_enable = res.data;
    //   });

    // let customerMap = {};
    // for (let customer in lists_of_enable) {
    //   customerMap[lists_of_enable[customer].Name] = true;
    // }

    // console.log(customerMap);

    await axios
      .get("/api/inventory/table", {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then(async (res) => {
        let customerMap = {};
        await axios
          .get("/api/inventory/generating", {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          })
          .then((res) => {
            console.log(res.data);
            for (let i in res.data) {
              customerMap[res.data[i]["company"]] = true;
            }
          });

        let tables = [];
        for (let i in res.data) {
          if (
            res.data[i]["values"][0][8] === "PENDING" &&
            res.data[i]["values"][0][0].toLowerCase() === "delivery" &&
            res.data[i]["values"][0][11] !== "" &&
            res.data[i]["values"][0][3] in customerMap &&
            (res.data[i]["values"][0][5] === "20kg Cyl" ||
              res.data[i]["values"][0][5] === "50kg Cyl" ||
              res.data[i]["values"][0][5] === "100kg Cyl")
          )
            tables.push({
              Movement: res.data[i]["values"][0][0],
              Batch: res.data[i]["values"][0][11],
              Status: res.data[i]["values"][0][8],
              "Customer Name": res.data[i]["values"][0][3],
              quantity: res.data[i]["values"][0][6],
              reference: res.data[i]["values"][0][4],
              product: res.data[i]["values"][0][19],
              download: `/api/inventory/createQR?batchNumber=${
                res.data[i]["values"][0][11]
              }&quantity=${res.data[i]["values"][0][6]}&clientName=${
                encodeURIComponent(res.data[i]["values"][0][3])
              }&expiryDate=${calculateExpiryDate(
                res.data[i]["values"][0][13]
              )}&dataQR=${encodeURIComponent(res.data[i]["values"][0][18])}`,
            });
        }
        console.log(tables);
        setTable(tables);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function checkAck(uid) {
    let token = await getAuthToken();

    return new Promise((resolve, reject) => {
      axios
        .post(
          "/api/document/checkAck",
          { uid },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getInvitationMailContent(setDate, setContent) {
    let token = await getAuthToken();

    axios
      .get("/api/mail/invitation", {
        headers: { "Content-Type": "application/json", Authorization: token },
      })
      .then((res) => {
        console.log(res.data);
        let [date, month] = res.data.date.split("/");
        if (date.length == 1) date = "0" + date;
        if (month.length == 1) month = "0" + month;
        setDate(`${date}/${month}`);
        setContent(res.data.message);
      });
  }

  async function saveInvitationMailContent(dateFull, message) {
    if (window.confirm("Do you want to save these changes")) {
      let token = await getAuthToken();

      let [day, month] = dateFull.split("/");

      let date = `${parseInt(day)}/${parseInt(month)}`;

      axios
        .patch(
          "/api/mail/invitation",
          { date, message },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((res) => {
          alert("Successfully saved the changes");
        })
        .catch((err) => {
          alert(err?.response?.data?.msg);
        });
    }
  }

  async function getReminderMailContent(setDate, setContent) {
    let token = await getAuthToken();

    axios
      .get("/api/mail/reminder", {
        headers: { "Content-Type": "application/json", Authorization: token },
      })
      .then((res) => {
        console.log(res.data);
        let [date, month] = res.data.date.split("/");
        if (date.length == 1) date = "0" + date;
        if (month.length == 1) month = "0" + month;
        setDate(`${date}/${month}`);
        setContent(res.data.message);
      });
  }

  async function getAckContent(setContent) {
    let token = await getAuthToken();

    axios
      .get("/api/mail/ack", {
        headers: { "Content-Type": "application/json", Authorization: token },
      })
      .then((res) => {
        setContent(res.data.message);
      });
  }

  async function saveAckContent(message) {
    if (window.confirm("Do you want to save these changes")) {
      let token = await getAuthToken();

      axios
        .patch(
          "/api/mail/ack",
          { message },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((res) => {
          alert("Successfully saved the changes");
        })
        .catch((err) => {
          alert(err?.response?.data?.msg);
        });
    }
  }
  async function getManualReminderMailContent(setContent) {
    let token = await getAuthToken();

    axios
      .get("/api/mail/manual", {
        headers: { "Content-Type": "application/json", Authorization: token },
      })
      .then((res) => {
        setContent(res.data.message);
      });
  }

  async function saveManualReminderMailContent(message) {
    if (window.confirm("Do you want to save these changes")) {
      let token = await getAuthToken();

      axios
        .patch(
          "/api/mail/manual",
          { message },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((res) => {
          alert("Successfully saved the changes");
        })
        .catch((err) => {
          alert(err?.response?.data?.msg);
        });
    }
  }

  async function saveReminderMailContent(dateFull, message) {
    if (window.confirm("Do you want to save these changes")) {
      let token = await getAuthToken();

      let [day, month] = dateFull.split("/");

      let date = `${parseInt(day)}/${parseInt(month)}`;

      axios
        .patch(
          "/api/mail/reminder",
          { date, message },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((res) => {
          alert("Successfully saved the changes");
        })
        .catch((err) => {
          alert(err?.response?.data?.msg);
        });
    }
  }

  async function userCheckAck() {
    let { ack, rows0, company, primaryName } = await checkAck(currentUser.uid);
    return { ack, rows0, company, primaryName };
  }

  async function updateExpiryOption(uid, expiryIncrement) {
    if (window.confirm("Are you sure you want to update the expiry option?")) {
      let token = await getAuthToken();

      await axios
        .patch(
          "/api/users/expiry",
          { uid, expiryIncrement },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((res) => {
          alert("Update Expiry Option Succesfully");
        })
        .catch((err) => {
          alert(err?.response?.data?.msg);
        });
    }
  }

  async function getNoAckEmail(setUsersData, selected) {
    let token = await getAuthToken();

    axios
      .get("/api/users/noAck", {
        headers: { "Content-Type": "application/json", Authorization: token },
      })
      .then((res) => {
        console.log(res.data);
        const userData = res.data.map((item, id) => {
          const _selected = selected.includes(item.email);
          return {
            ...item,
            id,
            _selected,
            _classes: [item._classes],
          };
        });
        setUsersData(userData);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function getNoAckNoAssigned(setUsersData, selected) {
    let token = await getAuthToken();
    let map = {};

    await axios
      .get("/api/users/noAckAndNoAssigned", {
        headers: { "Content-Type": "application/json", Authorization: token },
      })
      .then((res) => {
        for (let i in res.data) {
          console.log(res.data[i].uid);
          map[res.data[i].uid] = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    console.log("DEBUG :getNoAckNoAssigned");
    console.log(map);

    return map;
  }

  async function sendManualMail(selectedUser) {
    axios
      .post("/api/mail/manual", { emails: selectedUser })
      .then((res) => {
        window.alert("Mails sent!");
        window.location.reload(false);
      })
      .catch((err) => {
        window.alert(err?.response?.data?.msg);
      });
  }

  const value = {
    userList,
    pdfList,
    setUserList,
    checkAck,
    getUsers,
    getAllPDF,
    uploadPDF,
    reupload,
    rename,
    deletePDF,
    getPDF,
    createUser,
    deleteUser,
    getUser,
    updateNameAndEmail,
    updatePassword,
    getTypeOfPDF,
    updatePDFOptions,
    getUserPDFOptions,
    sendManualMail,
    updateStickerOptions,
    getUserStickerOptions,
    getUserDashboard,
    getStewardship,
    acknowledge,
    getTable,
    setPDFList,
    getInvitationMailContent,
    saveInvitationMailContent,
    saveReminderMailContent,
    getReminderMailContent,
    userCheckAck,
    updateExpiryOption,
    getManualReminderMailContent,
    saveManualReminderMailContent,
    getNoAckEmail,
    getAckContent,
    saveAckContent,
    getQIGenOptions,
  };

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
}
