import React, { useContext, useState, useEffect } from "react";
import { auth } from "../config/firebase";
import firebase from "firebase/app";
import axios from "axios";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function login(email, password) {
    return auth
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return auth.signInWithEmailAndPassword(email, password);
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        window.alert(error);
      });
    // return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function getAuthToken() {
    return currentUser.getIdToken(true);
  }

  useEffect(async () => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user !== null) {
        auth.currentUser.getIdTokenResult().then(async (idTokenResult) => {
          if (idTokenResult?.claims?.admin || idTokenResult?.claims?.staff) {
            user.role = idTokenResult?.claims?.admin ? "admin" : "staff";
            setCurrentUser(user);
          } else if (idTokenResult?.claims?.client1) {
            user.role = "client1";
            setCurrentUser(user);
          } else if (idTokenResult?.claims?.client0) {
            user.role = "client";
            user.client0 = true;
            let uid = user.uid;
            let token = await user.getIdToken(true);
            await axios
              .get(`/api/users/custom?uid=${uid}&fields=language`, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                },
              })
              .then((res) => {
                user.language = res.data.language;
                setCurrentUser(user);
              })
              .catch((err) => {
                alert(err?.response?.data?.msg);
              });
          } else {
            user.role = "client";
            user.client0 = false;
            let uid = user.uid;
            let token = await user.getIdToken(true);
            await axios
              .get(`/api/users/custom?uid=${uid}&fields=language`, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                },
              })
              .then((res) => {
                user.language = res.data.language;
                setCurrentUser(user);
              })
              .catch((err) => {
                alert(err?.response?.data?.msg);
              });
          }
        });
      } else {
        setCurrentUser(user);
      }

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
    resetPassword,
    getAuthToken,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
