import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";
import "./scss/style.scss";
// import Login from "./views/login/Login";
import "spinkit/spinkit.min.css";
import { Worker } from "@react-pdf-viewer/core";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const Login = React.lazy(() => import("./views/login/Login"));

function App() {
  const { currentUser } = useAuth();

  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.7.570/build/pdf.worker.min.js">
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            {currentUser === null || currentUser === undefined ? (
              <Route path="/" component={Login} />
            ) : (
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            )}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </Worker>
  );
}

export default App;
